import { useState, useEffect, useContext } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import { ThemeContext } from '@forma/forma-ui-kit';
import Slider from 'react-slick';

import styles from './applications-list.module.css';

const preloadImage = (src) => {
  let image = new Image();
  image.src = src;
  return image;
};

const ApplicationBlock = ({ name, list, content, image, image_hover }) => {
  const [ isHover, setIsHover ] = useState(false);

  useEffect(() => {
    preloadImage(image_hover);
  }, []);

  return (
    <div
      className={styles.block}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div className={styles.blockTitle} dangerouslySetInnerHTML={{ __html: name }}></div>
      <div className={styles.blockDescr}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
        {list && (
          <ul className={styles.blockList}>
            {list.map((item, listIndex) =>
              <li className={styles.blockListItem} key={`list_item_${listIndex}`}>{item}</li>
            )}
          </ul>
        )}
      </div>
      <img className={styles.image} src={image} alt={name} />
      {isHover &&
        <img className={styles.image} src={image_hover} alt={name} />
      }
    </div>
  );
};

const ApplicationsList = ({ items }) => {
  const { t } = useTranslation();
  const { viewport } = useContext(ThemeContext);


  if (!items) return null;

  return (
    <div className={styles.root}>
      {viewport === 'phone' ? (
        <>
          <div className={styles.title}>
            <Trans
              i18nKey="applications_forma"
              components={{ accent: <span className="accent-text" /> }}
            />
          </div>
          <div className={styles.subtitle}>{t('applications_forma_description')}</div>
          <Slider className={styles.slider} arrows={false} infinite={false} dots={true}>
            {items.map(({ button, ...item }, index) => (
              <div className={styles.item} key={`item_${index}`}>
                <ApplicationBlock {...item} />
              </div>
            ))}
          </Slider>
        </>
      ) : (
        <div className={styles.grid}>
          <div className={styles.itemTitle}>
            <div className={styles.title}>
              <Trans
                i18nKey="applications_forma"
                components={{ accent: <span className="accent-text" /> }}
              />
            </div>
            <div className={styles.subtitle}>{t('applications_forma_description')}</div>
          </div>
          {items.map(({ button, ...item }, index) => (
            <div className={styles.item} key={`item_${index}`}>
              <ApplicationBlock {...item} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ApplicationsList;
