import { useTranslation, Trans } from 'next-i18next';
import { Button } from '@forma/forma-ui-kit';
import Link from '@/components/Link';

import styles from './promo-banner.module.css';

const lkUrl = process.env.LK_URL;

const PromoBanner = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <Trans
          i18nKey="promo_banner_title"
          components={{ accent: <span className="accent-text" /> }}
        />
      </div>
      <div className={styles.buttons}>
        {/* <Button
          as={Link}
          className={styles.button}
          viewStyle="primary"
          href="/request"
        >
          {t('request_online_demo')}
        </Button> */}
        <Button
          as={Link}
          className={styles.button}
          viewStyle="primary"
          href={`${lkUrl}/register?lng=${i18n.language}`}
        >
          {t('create_account')}
        </Button>
      </div>
      <div className={styles.description}>
        <Trans
          i18nKey="promo_banner_description"
          components={{ tariffs: <Link className="accent-text" href="/tariffs" /> }}
        />
      </div>
    </div>
  )
};

export default PromoBanner;
