import { Trans } from 'next-i18next';

import styles from './homepage-title.module.css';

const HomepageTitle = () => {
  return (
    <div className={styles.root}>
      <Trans
        i18nKey="homepage_counter_title"
        components={{ accent: <span className="accent-text"  />, count: <span className={styles.count} /> }}
      />
    </div>
  );
};

export default HomepageTitle;
