import { useState } from 'react';
import { useTranslation, Trans } from 'next-i18next';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';

import styles from './questions.module.css';

const QuestionItem = ({ question, answer }) => {
  const [ isOpen, setOpen ] = useState(false);

  return (
    <div className={classNames(styles.item, isOpen && styles.open)}>
      <div className={styles.header} onClick={() => setOpen(!isOpen)}>
        <div className={styles.name}>{question}</div>
        <ReactSVG src="/icons/arrow-down.svg" className={styles.arrow} />
      </div>
      <div className={styles.content} dangerouslySetInnerHTML={{ __html: answer }} />
    </div>
  )
}

const Questions = ({ items }) => {
  const { t } = useTranslation();

  if (!items) return null;

  return (
    <div className={styles.root}>
      <div className={styles.label}>{t('faq')}</div>
      <div className={styles.title}>
        <Trans
          i18nKey="have_you_questions"
          components={{ accent: <span className="accent-text" /> }}
        />
      </div>

      <div className={styles.items}>
        {items.map((item, index) => (
          <QuestionItem {...item} key={index} />
        ))}
      </div>
    </div>
  )
};

export default Questions;
