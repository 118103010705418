import { useContext } from 'react';
import Slider from 'react-slick';
import { Trans } from 'next-i18next';
import classNames from 'classnames';
import { Button, ThemeContext } from '@forma/forma-ui-kit';
import Link from '@/components/Link';

import styles from './advantages-banner.module.css';

const AdvantagesBlockButton = ({ name, image, link }) => {
  return (
    <div className={classNames(styles.block, styles.blockButton)} style={{ backgroundImage: `url(${image})` }}>
      <div className={styles.blockButtons}>
        <Button viewStyle="primary" as={Link} href={link}>{name}</Button>
      </div>
    </div>
  );
};

const AdvantagesBlock = ({ name, list, content, image }) => {
  return (
    <div className={styles.block} style={{ backgroundImage: `url(${image})` }}>
      <div className={styles.blockTitle} dangerouslySetInnerHTML={{ __html: name }}></div>
      <div className={styles.blockDescr}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
        {list && (
          <ul className={styles.blockList}>
            {list.map((item, listIndex) =>
              <li className={styles.blockListItem} key={`list_item_${listIndex}`} dangerouslySetInnerHTML={{ __html: item }} />
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

const AdvantagesBanner = ({ items }) => {
  const { viewport } = useContext(ThemeContext);

  if (!items) return null;

  return (
    <div className={styles.root}>
      <h2 className={styles.title}>
        <Trans
          i18nKey="advantages_forma"
          components={{ accent: <span className="accent-text" /> }}
        />
      </h2>
      {viewport === 'phone' ? (
        <Slider className={styles.slider} arrows={false} infinite={false} dots={true}>
          {items.map(({ button, ...item }, index) => (
            <div className={styles.item} key={`item_${index}`}>
              <AdvantagesBlock {...item} />
            </div>
          ))}
        </Slider>
      ) : (
        <div className={styles.grid}>
          {items.map(({ button, ...item }, index) => (
            <div className={styles.item} key={`item_${index}`}>
              <AdvantagesBlock {...item} />
              {button &&
                <AdvantagesBlockButton {...button} />
              }
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AdvantagesBanner;
